<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("relocateRefreshTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-4"
          :class="contactErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="contact"
            outlined
            dense
            required
            :hide-details="contactErrors.length === 0"
            :error-messages="contactErrors"
            @input="$v.contact.$touch()"
            @blur="$v.contact.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("username") }}
            </template></v-text-field
          >
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="locationErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-textarea
            v-model="location"
            outlined
            :label="$t('location')"
            dense
            required
            hide-details
            rows="2"
            :error-messages="locationErrors"
            @input="$v.location.$touch()"
            @blur="$v.location.$touch()"
          ></v-textarea>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-16' : ''"
          justify="center"
        >
          <v-autocomplete
            v-model="selectedMembersToNotify"
            :items="membersToNotifyList"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('membersToNotify')"
            outlined
            dense
            required
            multiple
            item-text="mbcode_name"
            item-value="mbcode"
            @input="searchMembers = null"
            :search-input.sync="searchMembers"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-text-field
                  v-model="searchMembers"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchMembersToNotify')"
                  single-line
                  hide-details
                  flat
                  dense
                  variant="underlined"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2 chip-content">
                  {{ item.mbcode_name }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="relocateRefreshTicketSubmit"
            :disabled="$v.$invalid || submitButtonClicked"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="$v.$invalid || submitButtonClicked"
              @click="relocateRefreshTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
export default {
  mixins: [validationMixin],
  validations() {
    return {
      location: { maxLength: maxLength(4096) },
      contact: { required, maxLength: maxLength(30), minLength: minLength(5) },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedMembersToNotify: [],
      location: "",
      contact: "",
      searchMembers: null,
      submitButtonClicked: false,
    };
  },
  created() {
    if (
      this.ticketData &&
      this.ticketData.user_id &&
      this.companyUserMap[this.ticketData.user_id]
    ) {
      this.contact = this.companyUserMap[this.ticketData.user_id].name;
    }
  },
  methods: {
    checkFacilityType(facility_type) {
      //check if facility type is a 4 character code ending in X
      if (
        !facility_type ||
        (facility_type.length == 4 && facility_type.endsWith("X"))
      ) {
        return false;
      }

      // check to see if facility type is not in the array of facility types that are not required to respond
      const notRequiredFacilityType = ["LOCT", "COPY", "TEST", "ENCR", "PIGB"];
      return !notRequiredFacilityType.includes(facility_type);
    },
    showMember(member) {
      if (
        member.show &&
        member.deliver &&
        this.checkFacilityType(member.facility_type)
      ) {
        return true;
      }
      return false;
    },
    async relocateRefreshTicketSubmit() {
      this.submitButtonClicked = true;

      let relocateRefreshTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          location: this.location,
          caller: this.contact,
        },
      };
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      if (
        this.selectedMembersToNotify &&
        this.selectedMembersToNotify.length !== 0
      ) {
        apiData.data.membersToNotify = this.selectedMembersToNotify.join(",");
      } else apiData.data.membersToNotify = "";
      relocateRefreshTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionRelocateRefreshTicket",
        relocateRefreshTicketPayload
      );

      if (updateTicketResp.status === "error") {
        console.log("Error in Updating Relocate &Refresh Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "relocateRefreshTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.submitButtonClicked = false;
      this.closeDialog();
    },

    closeDialog() {
      this.location = "";
      this.selectedMembersToNotify = [];
      this.$emit("closeRelocateRefreshDialog");
    },
  },
  computed: {
    companyUserMap() {
      return this.$store.state.User.companyUserMap;
    },
    membersToNotifyList() {
      let membersList = [];
      if (
        this.ticketData &&
        this.ticketData.receipt &&
        this.ticketData.receipt.members
      ) {
        membersList = this.ticketData.receipt.members.map((member) => {
          if (this.showMember(member) && member.mbcode !== "EMLCFM") {
            return {
              mbcode: member.mbcode,
              name: member.name,
              mbcode_name: `${member.mbcode} - ${member.name}`,
            };
          }
        });
      }
      return membersList;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.minLength && errors.push(this.$t("callerMinLength"));
      !this.$v.contact.maxLength && errors.push(this.$t("callerMaxLength"));
      !this.$v.contact.required && errors.push(this.$t("callerRequired"));
      return errors;
    },
    locationErrors() {
      const errors = [];
      if (!this.$v.location.$dirty) return errors;
      !this.$v.location.maxLength && errors.push(this.$t("locationMaxLength"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}

.chip-content {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
